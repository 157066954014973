import React, { useState, useEffect } from 'react';
import Slider from "react-slick";

function Activitypic() {

    const [autopPlaySpeed, setAutoPlaySpeed] = useState(5000);
    const [carouselData, setCarouselData] = useState([]);

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: autopPlaySpeed,
        afterChange: (e) => {
            if (document.getElementsByClassName('slick-active')[0].getElementsByClassName('item')[0].childNodes[0].tagName.toLowerCase() === 'video') {
                document.getElementsByClassName('slick-active')[0].getElementsByClassName('item')[0].childNodes[0].play();
                const durationTime = document.getElementsByClassName('slick-active')[0].getElementsByClassName('item')[0].childNodes[0].duration * 1000;
                const currentTime = document.getElementsByClassName('slick-active')[0].getElementsByClassName('item')[0].childNodes[0].currentTime * 1000;
                setAutoPlaySpeed(durationTime - currentTime);
            } else {
                setAutoPlaySpeed(5000);
            }
        },
    };

    useEffect(() => {
        fetch('api/Activity')
            .then(response => {
                return response.json();
            })
            .then(json => {
                if (json.length == 0) {
                    setCarouselData([{id: 0, src: "no_activity.jpg", alt: "�ثe�S������"}]);
                }
                else {
                    setCarouselData(json);
                }
            })
            .catch(err => {
                console.error(err);
            });
    }, []);

    return (
        <div className="activity-info">
            <Slider {...settings}>
                {carouselData.map((e) => {
                    return (
                        <div key={e.id} className="item">
                            <div class="item-align-wrapper">
                                <img src={"/images/activity/" + e.src} alt={e.alt} />
                            </div>
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
}

export default Activitypic;
